<template>
    <div id="main-menu" class="text-end">
        <div class="d-flex align-items-center justify-content-end d-lg-none">
            <i @click="open = true" class="bi bi-list text-light fs-1"></i>
            
            <a href="/cart" id="cart" v-if="cartEnabled">
                <i class="bi bi-cart-fill text-light ms-2 fs-4"></i>
                <span v-if="cart" class="badge rounded-pill bg-primary">{{ cart.length }}</span>
            </a>
        </div>

        <nav :class="{'nav-open': open}"
            class="d-flex flex-column flex-lg-row align-items-center justify-content-lg-end">
            <i @click="open = false" class="bi bi-x-lg align-self-end fs-1 text-light d-lg-none"></i>
            <a href="/" class="text-white text-hover-primary text-decoration-none mb-4 mb-lg-0 me-lg-5">Home</a>
            <a href="/products" class="text-white text-hover-primary text-decoration-none mb-4 mb-lg-0 me-lg-5">Products</a>
            <div class="nav-item dropdown mb-4 mb-lg-0 me-lg-5" v-if="pages.length > 0">
                <a class="text-white text-hover-primary text-decoration-none dropdown-toggle" 
                    href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    About us
                </a>
                <ul class="dropdown-menu">
                    <li>
                        <a class="dropdown-item" href="/about">
                            Our story
                        </a>
                    </li>

                    <li v-for="(link, index) in pages" :key="index">
                        <a class="dropdown-item" :href="'/'+link.slug">
                            {{ link.name }}
                        </a>
                    </li>
                </ul>
            </div>
            <a v-else href="/about" class="text-white text-hover-primary text-decoration-none mb-4 mb-lg-0 me-lg-5">Our Story</a>
            <a href="/contact" class="btn btn-light rounded-0">Contact us</a>
            <a href="/cart" class="btn btn-white rounded-0 ms-4 px-3 d-none d-lg-block" id="cart" v-if="cartEnabled">
                Cart <span v-if="cart" class="badge rounded-pill bg-primary">{{ cart.length }}</span>
            </a>
        </nav>
    </div>
</template>

<script>
    export default {
        name: 'Menu',
        props: {
            pages: {
                required: false
            },

            cart: {
                required: false
            },

            cartEnabled: {
                default: false
            }
        },
        
        data(){
            return{
                open: false
            }
        }
    }
</script>