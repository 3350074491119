import Swiper from "swiper";
import { Pagination, Autoplay } from 'swiper/modules';

Swiper.use([Pagination, Autoplay]);

const swiper = new Swiper('.swiper', {
    loop: true,
    slidesPerView: 1,
    autoHeight: true,
    autoplay: {
        delay: 8000,
        disableOnInteraction: true,
    },
    breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        991: {
          slidesPerView: 3,
          spaceBetween: 30
        }
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
        dynamicBullets: true
    }
});