<template>
    <carousel ref="gallerySlider" :wrap-around="true" :items-to-show="1" :touch-drag="false" :mouse-drag="false" v-if="slides.length > 0">
        <slide v-for="(slide, index) in slides" :key="index">
            <div class="h-100 w-100 bg-cover" aria-role="img" 
                :alt="'Slide '+index"
                :style="{'background-image': 'url('+slide+')'}">
            </div>
        </slide>
    
        <template #addons>
            <carousel ref="thumbnailSlider" class="thumbnail-slider d-none d-lg-block" v-if="slides.length > 3"
                    :wrap-around="true" :items-to-show="3" :touch-drag="false" :mouse-drag="false">
                <slide v-for="(slide, index) in slides" :key="index" @click="changeSlide(index)">
                    <div class="slide bg-cover shadow" aria-role="img" 
                        :alt="'Slide '+index"
                        :style="{'background-image': 'url('+slide+')'}">
                    </div>
                </slide>
            </carousel>

            <div class="controls" v-if="slides.length > 1">
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-end">
                            <navigation v-if="arrows">
                                <template #next>
                                    <i @click="changeNext()" class="bi bi-arrow-right-short"></i>
                                </template>

                                <template #prev>
                                    <i @click="changePrev()" class="bi bi-arrow-left-short"></i>
                                </template>
                            </navigation>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </carousel>
</template>

<script>
    import { ref, watch, onMounted, computed } from 'vue'

    export default {
        name: 'Gallery',
        props: {
            arrows: {
                type: Boolean,
                required: false,
                default: true
            },
            dots: {
                type: Boolean,
                required: false,
                default: true
            },
            slides: {
                type: Object,
                required: true
            }
        },
        setup(props, context) {
            const gallerySlider = ref(null);
            const thumbnailSlider = ref(null);

            function changeSlide(slide){
                gallerySlider.value.slideTo(slide);
                thumbnailSlider.value.slideTo(slide);
            }

            function changeNext(){
                thumbnailSlider.value.next()
            }

            function changePrev(){
                thumbnailSlider.value.prev()
            }

            return{
                changeSlide,
                changeNext,
                changePrev,
                gallerySlider,
                thumbnailSlider
            }
        },
    }
</script>