<template>
    <div class="showcase">
        <div class="showcase-image position-relative">
            <img class="w-100" :src="image" :alt="title+' showcase image'">

            <div v-for="(bullet, index) in bullets" :key="index"  
                class="bullet position-absolute bg-primary" 
                :class="{active: activeLabel == index}"
                @mouseover="activeLabel = index"
                @mouseout="activeLabel = null"
                :style="{
                    'left': labelData(bullet.attributes.label)[0]+'%', 
                    'top': labelData(bullet.attributes.label)[1]+'%',
                    'animation-delay': count*index+'s'
                }">
                <div class="card bullet-text rounded-0 shadow border-0 py-2 px-3"
                    :style="{
                        'top': labelData(bullet.attributes.label)[1] <= 50 ? '10px' : 'unset', 
                        'bottom': labelData(bullet.attributes.label)[1] > 50 ? '10px' : 'unset',
                        'left': labelData(bullet.attributes.label)[0] <= 50 ? '10px' : 'unset', 
                        'right': labelData(bullet.attributes.label)[0] > 50 ? '10px' : 'unset',
                    }">
                    {{ labelData(bullet.attributes.label)[2] }}
                </div>
            </div>
        </div>

        <div class="showcase-details mt-3">
            <h3>{{ title }}</h3>
            <p v-if="subtitle">{{ subtitle }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Showcase',
        props: {
            count: {
                type: Number,
                required: true
            },
            image: {
                type: String,
                required: true
            },
            title: {
                type: String,
                required: true
            },
            subtitle: {
                type: String,
                required: false
            },
            productName: {
                type: String,
                required: false
            },
            productUrl: {
                type: String,
                required: false
            },
            bullets: {
                type: Object,
                required: false
            },
        },
        data(){
            return{
                activeLabel: null
            }
        },
        methods: {
            labelData(label){
                return label.split(',');
            }
        }
    }
</script>