import { createApp } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import Menu from './layout/Menu.vue';
import Slider from './components/Slider.vue';
import Gallery from './components/Gallery.vue';
import NoticeBar from './components/NoticeBar.vue';
import ProductGallery from './components/ProductGallery.vue';
import Showcase from './components/Showcase.vue';
import GoogleMap from './components/GoogleMap.vue';
import ProductPurchase from './components/ProductPurchase.vue';

const app = createApp()
app.component('carousel', Carousel);
app.component('slide', Slide);
app.component('notice-bar', NoticeBar);
app.component('pagination', Pagination);
app.component('navigation', Navigation);
app.component('main-menu', Menu);
app.component('slider', Slider);
app.component('gallery', Gallery);
app.component('product-gallery', ProductGallery);
app.component('showcase', Showcase);
app.component('google-map', GoogleMap);
app.component('product-purchase', ProductPurchase);
app.mount('#app')