<template>
    <carousel @update:modelValue="slideChanged" :items-to-show="1">
        <slide ref="slide" v-for="(slide, index) in slides" :key="index">
            <div class="video-container" v-if="slide.split('.').pop() == 'mp4'">
                <video class="hero-video" :ref="'video'+index" :src="slide" muted autoplay loop/>
            </div>

            <div v-else class="h-100 w-100 bg-cover" aria-role="img" 
                :alt="'Slide '+index"
                :style="{'background-image': 'url('+slide+')'}">
            </div>
        </slide>
    
        <template #addons>
            <div class="controls">
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-end">
                            <navigation v-if="arrows"/>
                            <pagination v-if="dots"/>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </carousel>
</template>

<script>
    export default {
        name: 'Slider',
        props: {
            arrows: {
                type: Boolean,
                required: false,
                default: true
            },
            dots: {
                type: Boolean,
                required: false,
                default: true
            },
            slides: {
                type: Object,
                required: true
            }
        },
        methods: {
            slideChanged(e){
                let videos = document.getElementsByClassName('hero-video');
                for (let i = 0; i < videos.length; i++) {
                    videos[i].pause();
                }

                let activeVideo = this.$refs['video'+e];
                if(activeVideo){
                    activeVideo[0].play()
                }
            }
        }
    };
</script>