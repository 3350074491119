<template>
    <carousel id="notice-bar" :transition="600" :autoplay="5000" class="bg-primary py-1" ref="slider" :wrap-around="true" :items-to-show="1" :touch-drag="false" :mouse-drag="false" v-if="slides.length > 0">
        <slide v-for="(slide, index) in slides" :key="index">
            <a :href="slide.link" class="text-white text-sm mb-1 td-none px-5" v-if="slide.link">{{ slide.notice }}</a>
            <p class="text-white text-sm mb-1 px-5" v-else>{{ slide.notice }}</p>
        </slide>
    
        <template #addons>
            <div class="controls" v-if="slides.length > 1">
                <div class="container">
                    <div class="row">
                        <div class="w-100 d-flex align-items-center">
                            <navigation>
                                <template #next>
                                    <i @click="changeNext()" class="bi bi-chevron-right fs-6"></i>
                                </template>

                                <template #prev>
                                    <i @click="changePrev()" class="bi bi-chevron-left fs-6"></i>
                                </template>
                            </navigation>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </carousel>
</template>

<script>
    import { ref } from 'vue'

    export default {
        name: 'NoticeBar',
        props: {
            slides: {
                type: Object,
                required: true
            }
        },
        setup() {
            const slider = ref(null);

            function changeNext(){
                slider.value.next()
            }

            function changePrev(){
                slider.value.prev()
            }

            return{
                changeNext,
                changePrev,
            }
        },
    }
</script>

<style lang="scss">
    #notice-bar{
        overflow: hidden;

        p,a{
            font-size: 13px;
            line-height: 18px;
        }

        .controls{
            button{
                height: 100%;
                top: 0 !important;
                bottom: 0 !important;
                display: flex;
                margin: auto;
                align-items: center;

                i{
                    margin-bottom: 2px;
                }

                &:hover{
                    background-color: transparent;
                }
            }
        }
    }
</style>