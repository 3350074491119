<template>
    <GoogleMap :api-key="apiKey" style="width: 100%; height: 55vw" :center="center" :zoom="15">
        <Marker :options="{ position: center, icon: icon }" />
    </GoogleMap>
</template>

<script>
    import { GoogleMap, Marker } from "vue3-google-map";

    export default {
        props: {
            apiKey: {
                type: String,
                required: true
            },
            lat: {
                required: true
            },
            lng: {
                required: true
            },
            icon: {
                required: false
            }
        },
        components: {
            GoogleMap,
            Marker
        },
        computed: {
            center(){
                return { lat: this.lat, lng: this.lng }
            }
        }
    }
</script>