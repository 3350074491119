<template>
    <div>
        <FsLightbox
            v-if="slides.length > 0"
            :toggler="lightbox"
            :sources="slides"
            :slide="slide"
        />

        <carousel ref="productSlider" class="product-slider" :wrap-around="false" :items-to-show="1" :touch-drag="false" :mouse-drag="false" v-if="slides.length > 0">
            <slide v-for="(slide, index) in slides" :key="index">
                <div class="h-100 w-100 bg-cover" aria-role="img" 
                    :alt="'Slide '+index"
                    @click="openLightboxOnSlide(index+1)"
                    :style="{'background-image': 'url('+slide+')'}">
                </div>
            </slide>

            <template #addons>
                <div class="expand" @click="openLightboxOnSlide(slide+1)">
                    <i class="bi bi-arrows-angle-expand"></i>
                </div>
            </template>
        </carousel>

        <carousel ref="thumbnailSlider" class="product-thumbnails my-3" v-if="slides.length > 0"
                :wrap-around="false" :items-to-show="3" :touch-drag="false" :mouse-drag="false">
            <slide v-for="(slide, index) in slides" :key="index" class="px-1" @click="changeSlide(index)">
                <div class="slide h-100 w-100 bg-cover" aria-role="img" 
                    :alt="'Slide '+index"
                    :style="{'background-image': 'url('+slide+')'}">
                </div>
            </slide>
        </carousel>
    </div>
</template>

<script>
    import { ref, watch } from 'vue'
    import FsLightbox from "fslightbox-vue/v3";

    export default {
        name: 'Gallery',
        components: {FsLightbox},
        props: {
            arrows: {
                type: Boolean,
                required: false,
                default: true
            },
            dots: {
                type: Boolean,
                required: false,
                default: true
            },
            slides: {
                type: Object,
                required: true
            },
            current: {
                required: false,
                default: 0
            }
        },
        setup(props, context) {
            const productSlider = ref(null);
            const thumbnailSlider = ref(null);
            const lightbox = ref(false);
            const slide = ref(1);

            function changeSlide(index){
                productSlider.value.slideTo(index);
                thumbnailSlider.value.slideTo(index);
                slide.value = index;
            }

            function changeNext(){
                thumbnailSlider.value.next()
            }

            function changePrev(){
                thumbnailSlider.value.prev()
            }
            
            function openLightboxOnSlide(number) {
                slide.value = number;
                lightbox.value = !lightbox.value;
            }

            watch(() => props.current, (index) => {
                if(index){
                    changeSlide(index-1)
                }
            })

            return{
                changeSlide,
                changeNext,
                changePrev,
                productSlider,
                thumbnailSlider,
                lightbox,
                openLightboxOnSlide,
                slide
            }
        },
    }
</script>